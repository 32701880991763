<template>
  <div v-if='hasSubnavigations'
    class='main-nav-container-with-sub'>
    <div class='main-nav cursor-pointer'
      :class='matchingRouteClass(nav.route)'
      @click='goToDefaultNavRoute'>
      <span class='hidden lg:inline-block pr-1 h-8'>&nbsp;</span>
      {{ nav.name }}
      <span class='hidden lg:inline-block border-r pr-1 h-8'>&nbsp;</span>
      <img v-if='nav.imageUrl'
        :src='nav.imageUrl' 
        class='inline-block h-full'
        :class='nav.imageCssClasses'
        :style='nav.imageCssStyles'>
    </div>
    <ul class='sub-nav-container'>
      <li v-for='(subnav, index) in subNavigationsFilteredByLogin'
        :key='`sub-nav-${index}`'
        class='sub-nav'>
        <router-link
          v-if="subnav.actionType === 'open_internal_route'"
          :to='{ name: subnav.route }'
          class='sub-nav-link'
          @click.native.stop='offMenu'>
          {{ subnav.name }}
          <img v-if='subnav.imageUrl' :src='subnav.imageUrl' class='inline-block h-full'>
        </router-link>
        <button v-else-if="subnav.actionType === 'open_external_link'"
          class='sub-nav-link'
          @click.stop='clickMenuExternalLink(subnav)'>
          {{ subnav.name }}
          <img v-if='subnav.imageUrl' :src='subnav.imageUrl' class='inline-block h-full'>
        </button>
      </li>
    </ul>
  </div>
  <div v-else
    class='flex flex-col justify-center'>
    <router-link
      v-if="nav.actionType === 'open_internal_route'"
      :to='{ name: nav.route }'
      class='main-nav'
      :class='matchingRouteClass(nav.route)'
      @click.native='offMenu'>
      <span class='hidden lg:inline-block pr-1 h-8'>&nbsp;</span>
      {{ nav.name }}
      <span class='hidden lg:inline-block border-r pr-1 h-8'>&nbsp;</span>
      <img v-if='nav.imageUrl'
        :src='nav.imageUrl' 
        class='inline-block h-full'
        :class='nav.imageCssClasses'
        :style='nav.imageCssStyles'>
      
    </router-link>
    <button v-else-if="nav.actionType === 'open_external_link'"
       class='main-nav'
       @click='clickMenuExternalLink(nav)'>
       <span class='hidden lg:inline-block pr-1 h-8'>&nbsp;</span>
      {{ nav.name }}
      <span class='hidden lg:inline-block border-r pr-1 h-8'>&nbsp;</span>
      <img v-if='nav.imageUrl'
        :src='nav.imageUrl' 
        class='inline-block h-full'
        :class='nav.imageCssClasses'
        :style='nav.imageCssStyles'>
    </button>
    <div v-else-if="nav.actionType === 'open_search'"
       :class='showSearchInMenu'
       @click='openSearch'>
       <span class='hidden lg:inline-block pr-1 h-8'>&nbsp;</span>
      <img v-if='nav.imageUrl'
        :src='nav.imageUrl' 
        class='inline-block h-full mr-2'
        :class='nav.imageCssClasses'
        :style='nav.imageCssStyles'>
      {{ nav.name }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HeaderNav',
  props: ['nav'],
  computed: {
    ...mapState('users', [
      'hasValidToken',
      'isAlreadyPaid',
    ]),
    hasSubnavigations () {
      return this.nav.subnavigations && 
             this.nav.subnavigations.length > 0 && 
             this.subNavigationsFilteredByLogin.length > 0
    },
    subNavigationsFilteredByLogin () {
      return this.nav.subnavigations.filter(navigation => {
        return !navigation.showCondition || 
                navigation.showCondition === 'always' ||
                (this.hasValidToken && navigation.showCondition == 'onlyWhenLoggedIn') || // if logged in, and show only when logged in
                (!this.hasValidToken && navigation.showCondition == 'onlyWhenLoggedOut') ||
                (this.isAlreadyPaid && navigation.showCondition == 'onlyWhenPaid') ||
                (!this.isAlreadyPaid && navigation.showCondition == 'onlyWhenNotPaid')
      })
    },
    isTouchDevice () {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0))
    },
    showSearchInMenu () {
      return this.isTouchDevice ? 'hidden' : 'main-nav cursor-pointer block flex-shrink-0 flex-grow-0'
    },
  },
  methods: {
    offMenu () {
      this.$emit('off-menu')
    },
    openSearch () {
      this.offMenu()
      this.$modal.show('search-modal')
    },
    goToDefaultNavRoute () {
      this.offMenu()
      this.$router.push({ name: this.nav.route }).catch(() => {})
    },
    matchingRouteClass (route) {
      if (this.$route.name) {     
        if (route === 'Vods') {
          return (this.$route.name.includes('Vod')) ? 'router-link-exact-active' : ''
        } else if (route === 'Sponsors') {
          return (this.$route.name.includes('Sponsor')) ? 'router-link-exact-active' : ''
        } else if (route === 'Posters') {
          return (this.$route.name.includes('Poster')) ? 'router-link-exact-active' : ''
        } else if (route === 'Live') {
          return (this.$route.name === 'Session') ? 'router-link-exact-active' : ''
        } else if (route === 'PreregistrationInformation') {
          return (this.$route.name.includes('PreregistrationInformation')) ? 'router-link-exact-active' : ''
        } else if (route === 'AbstractRegistrationInformation') {
          return (this.$route.name.includes('AbstractRegistrationInformation')) ? 'router-link-exact-active' : ''
        } else if (route === 'Programs') {
          return (['Programs', 'ProgramDetails'].includes(this.$route.name)) ? 'router-link-exact-active' : ''
        } else if (route === 'Notices') {
          return (this.$route.name.includes('Notices')) ? 'router-link-exact-active' : ''
        } 
       } else {
        return ''
       }
    },
    clickMenuExternalLink (subnav) {
      this.offMenu()
      if (subnav.targetSamePage) {
        return window.location.href = subnav.link
      } else {
        window.open(subnav.link, '_blank')
      }
    },
  }
}
</script>
<style lang='scss'>
  .main-nav-container-with-sub .sub-nav {
    @apply text-left pl-4 font-normal text-xs;
  }

  .main-nav {
    @apply h-auto uppercase text-gray-800 whitespace-nowrap leading-10 pl-4 pr-16 py-1 flex flex-row justify-center items-center;
  }

  .sub-nav-link {
    @apply uppercase text-gray-800 text-base whitespace-nowrap leading-10 mx-6 px-4 py-2;
  }

  .main-nav-container-with-sub > .main-nav.router-link-active {
    @apply bg-transparent border-l-0 text-gray-800 font-normal;
  }

  .main-nav.router-link-active,
  .sub-nav-link.router-link-active {
    color: var(--eventMainColor);
    border-color: var(--eventMainColor);
    background-color: #FAFAFA;
    @apply border-l-4 font-bold;
  }
 
.top-nav-menu {
  .main-nav-container-with-sub .sub-nav {
    @apply pr-2 py-2;
  }

  .main-nav-container-with-sub > .sub-nav-container {
    display: none;
    background-color: white;
    @apply py-2 shadow-md rounded-b-md z-10;
  }

  .main-nav-container-with-sub:hover > .sub-nav-container {
    position: absolute;
    display : block;
    margin-block-start: 0;
    margin-block-end: 0;
    top: 4rem;
  }

  .main-nav {
    @apply h-full py-3 mb-0 px-0 border-b-4 border-transparent;
  }

  .sub-nav-link {
    @apply mx-0;
  }

  .main-nav-container-with-sub > .main-nav.router-link-exact-active {
    color: var(--eventMainColor);
    @apply font-bold;
  }

  .main-nav.router-link-exact-active {
    color: var(--eventMainColor);
    border-color: var(--eventMainColor);
    @apply border-b-4 border-l-0 bg-transparent font-bold;
  }

  .sub-nav-link.router-link-active {
    @apply bg-transparent;
  }
}
</style>
