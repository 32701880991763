<template>
  <div 
    class='border-b h-16 bg-white sticky left-0 top-0 z-30 right-0'
    :class='fontSize'>
    <nav class='layout-container w-full h-full flex flex-row items-stretch px-2 lg:px-0 justify-between relative'>
      <div class='flex-grow-0 flex-shrink-0 flex flex-row justify-start items-center' style='max-width:300px;'>
        <img 
          :src='eventConfigLogoImageUrl'
          class='block cursor-pointer h-6 lg:h-8'
          :style='eventConfigLogoImageCssStyle'
          @click='goToConfigedPage'/>
        <div v-if='false'
            class='ml-4 text-normal font-bold py-2 px-4 rounded-xl shadow'
            style='background-color: var(--lightColor);'>
          {{ daysLeft }}
        </div>
      </div>
      <div :class='navMenuCss'>
        <header-nav
          v-for='(nav, index) in headerLinks'
          :key='`nav-${index}`'
          :nav='nav'
          @off-menu='offMenu' />
      </div>
      <div :class='mobileOrTouchControls'>
        <button v-if='searchNav'
          @click='openSearch'>
          <img
            :src='searchNav.imageUrl' 
            class='inline-block h-full mr-2'
            :class='searchNav.imageCssClasses'
            :style='searchNav.imageCssStyles'>
        </button>
        <button
          @click='toggleMenu'>
          <menu-icon/>
        </button>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { MenuIcon }   from '@vue-hero-icons/outline'
import HeaderNav from '@/components/header-nav/HeaderNav.vue'

export default {
  name: 'DemoLayoutHeader',
  components: {
    HeaderNav,
    MenuIcon,
  },
  data () {
    return {
      showSideNavMenu: false,
    }
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
      'isAlreadyPaid',
    ]),
    ...mapGetters('events', [
      'eventConfigNavigations',
      'eventConfigLogoImageUrl',
      'eventConfigLogoImageCssStyle',
      'eventMainThemeColor',
      'showingEventId',
      'eventMenuLogoLink',
    ]),
    isTouchDevice() {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0))
    },
    isSmallScreen () {
      return window.innerWidth < 1024
    },
    mobileOrTouchControlsMenuLayout () {
      return this.isTouchDevice || this.isSmallScreen
    },
    mobileOrTouchControls () {
      return (this.mobileOrTouchControlsMenuLayout) ? 'flex flex-row justify-end items-center gap-x-0' : 'hidden'
    },
    showMenuCss () {
      return (this.showSideNavMenu) ? 'show-side-nav-menu' : ''
    },
    navMenuCss () {
      return (this.mobileOrTouchControlsMenuLayout) ? `side-nav-menu ${this.showMenuCss}` : 'top-nav-menu'
    },
    searchNav () {
      return this.eventConfigNavigations.find(nav => nav.actionType === 'open_search')
    },
    headerLinks () {
      return this.eventConfigNavigations.filter(navigation => {
        return !navigation.showCondition || 
               !navigation.showCondition === 'do_not_show' ||
                navigation.showCondition === 'always' ||
               ( this.hasValidToken && navigation.showCondition == 'onlyWhenLoggedIn') || // if logged in, and show only when logged in
               (!this.hasValidToken && navigation.showCondition == 'onlyWhenLoggedOut') ||
               ( this.isAlreadyPaid && navigation.showCondition == 'onlyWhenPaid') ||
               (!this.isAlreadyPaid && navigation.showCondition == 'onlyWhenNotPaid')
      })
    },
    fontSize () {
      return (this.showingEventId === 5) ? 'text-lg' : 'text-base'
    },
    headerLogoStyle () {
      return (this.showingEventId === 17) ? 'header-long-logo' : 'header-logo'
    },
    daysLeft () {
      if (this.showingEventId === 30) {
        // const today = new Date()
        // const eventDate = new Date('2023-03-17')
        // const diffTime = Math.abs(eventDate - today)
        // let diffDays = diffTime > 0 ? Math.floor(diffTime / (1000 * 60 * 60 * 24)) : Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        // if (diffDays > 0) {
        //   return `D+${diffDays}`
        // } else if (diffDays === 0) {
        //   return `D-Day
        // } else {
        //   return `D-${diffDays}`
        // }
        return 'D-Day'
      } else {
        return ''
      }
    },
  },
  methods: {
    goToConfigedPage () {
      if (this.eventMenuLogoLink.includes('http')) {
        window.open(this.eventMenuLogoLink, '_blank')
      } else if (this.eventMenuLogoLink) {
        this.$router.push({name: this.eventMenuLogoLink}).catch(() => {})
      } else {
        // do nothing
      }
    },
    toggleMenu () {
      this.showSideNavMenu = !this.showSideNavMenu
    },
    offMenu () {
      this.showSideNavMenu = false
    },
    openSearch () {
      this.offMenu()
      this.$modal.show('search-modal')
    },
  }
}
</script>

<style lang='scss'>
  .top-nav-menu {
    @apply flex-grow flex flex-row justify-end text-center;
  }

  .side-nav-menu {
    @apply hidden bg-white;
  }
  .side-nav-menu.show-side-nav-menu {
    @apply fixed right-0 shadow-md rounded-b-md flex flex-col justify-start overflow-y-auto pb-16 z-10 text-left;
    top: 4rem; // size of navbar
    height: calc(100vh - 4rem);
  }
</style>
